import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import marked from 'marked'

import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'

const PostTitle = styled.h1`
  text-align: center;
`

const PostBody = styled.div`
  max-width: 42em;
  margin: 0 auto 3rem;
`

const LinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            {
              name: 'description',
              content: siteDescription,
            },
          ]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />
        <PostTitle>{post.frontmatter.title}</PostTitle>
        <PostBody
          dangerouslySetInnerHTML={{
            __html: marked(post.frontmatter.description),
          }}
        />
        <Gallery gallery={post.frontmatter.gallery} />
        <LinkList style={{}}>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </LinkList>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        gallery {
          publicURL
          extension
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`
