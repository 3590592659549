import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const GalleryItemHolder = styled.div`
  margin-bottom: 1.8rem;
`

const extractVideoPropertiesFromUrl = url => {
  const standardizedUrl = url.toLowerCase()
  const autoplay = standardizedUrl.includes('autoplay')
  const controls = !autoplay
  const loop = standardizedUrl.includes('loop')
  const muted = autoplay
  const playsInline = autoplay

  return {
    autoplay,
    controls,
    loop,
    muted,
    playsInline,
  }
}

const Video = ({ publicURL, autoplay, controls, loop, playsInline, muted }) => {
  return (
    <video
      width="100%"
      autoPlay={autoplay}
      loop={loop}
      controls={controls}
      muted={muted}
      playsInline={playsInline}
      crossOrigin="anonymous"
    >
      <source src={`${publicURL}#t=0.1`} type="video/mp4" />
    </video>
  )
}

const GalleryItem = media => {
  if (media.extension === 'mp4') {
    return (
      <Video {...media} {...extractVideoPropertiesFromUrl(media.publicURL)} />
    )
  }

  return <Img fluid={media.childImageSharp.fluid} />
}

const Gallery = ({ gallery }) => (
  <div>
    {gallery.map(media => (
      <GalleryItemHolder>
        <GalleryItem {...media} />
      </GalleryItemHolder>
    ))}
  </div>
)

export default Gallery
